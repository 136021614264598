import 'moment/locale/vi';
import Moment from "react-moment";
import React  from "react";

const Message = (props) => {
    return (
        <article className={"msg-container " + props.highLightClass} key={props.id}>
            <div className="msg-box">
                <div className="flr">
              <span className="timestamp">
                <Moment locale="vi"
                        to={props.time} toNow/>
              </span>
                    <div className="messages">
                        <p className="msg">
                            {props.content || ""}
                        </p>
                    </div>
                </div>
            </div>
        </article>
    )
}

export default React.memo(Message);
