let Constant = {
    LocalStorage: {
        DeviceId: "_deviceId",
        LastAction: "_lastAction",
        LastKhan: "_lastKhan",
        PlayAudio: "_playAudio",
    },
    AudioMode: {
        On: "on",
        Off: "off"
    },
}

export default Constant;