import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import "./App.css";
import Constant from "./config/constant";
import moment from "moment";
import Countdown from "react-countdown";
import ongdiaSmall from "./assets/img/ongdia-small-min.png";
import vectorIcon from "./assets/img/vector.svg";
import fbIcon from "./assets/img/fb-icon.svg";
import volumn from "./assets/img/volume.svg";
import volumeSlash from "./assets/img/volume_slash.svg";
import bookmarkIcon from "./assets/img/bookmark_icon.svg";
import FadeInAnimation from "./components/FadeInAnimation";
import { FacebookShareButton } from "react-share";
import GoogleAdSense from "react-simple-adsense";
import { isTablet } from 'react-device-detect';
import Message from "./message";
const { io } = require("socket.io-client");

let socket = undefined;
const API_BASE_URL = process.env.REACT_APP_API_URL;

const App = () => {

  let [listMsg, setListMsg] = useState([]);
  let [completedLoadMsg, setCompletedLoadMsg] = useState(false);
  let [msgContent, setMsgContent] = useState("");
  let [highlightMsg, setHighlightMsg] = useState(false);
  let [vaiBtnClss, setvaiBtnClss] = useState("btn-vai-normal");
  let [allowChat, setAllowChat] = useState(true);
  let [allowVai, setAllowVai] = useState(true);
  let [showVaiIcon, setShowVaiIcon] = useState(false);
  let [canPlay, setCanPlay] = useState(false);
  let [initSoundCompleted, setInitSoundCompleted] = useState(false);
  let [newMsg, setNewMsg] = useState(null);

  let [chatPageNumber, setChatPageNumber] = useState(0);
  let [loading, setLoading] = useState(false);

  const [width] = useState(window.innerWidth);
  let musicUrls = (process.env.REACT_APP_MUSIC_LINK || "").split(",");
  let _audio = new Audio(musicUrls[Math.floor(Math.random() * musicUrls.length)].trim());
  _audio.loop = true;

  const [audio] = useState(_audio);
  const [playingAudio, setPlayingAudio] = useState(false);
  const [volumnIcon, setVolumnIcon] = useState(volumn);

  const [enableAds] = useState(process.env.REACT_APP_ADS === "on")

  const [regex, setRegex] = useState(null);
  const [showWarningChat, setShowWarningChat] = useState(false);


  const chatBoxRef = useRef(null);
  const chatInputBoxRef = useRef(null);
  const [chatBoxScrollHeight, setChatBoxScrollHeight] = useState(0);

  useEffect(() => {
    const handleScroll = event => {
      const { scrollY, innerHeight, innerWidth } = window
      const hiddenClassName = "hidden"
      // only handle scroll in mobile
      if (innerWidth > 500 ||
          chatInputBoxRef == null ||
          chatBoxRef == null ||
          allowChat === false
      ) {
        return
      }

      const currentInputRef = chatInputBoxRef.current
      if (scrollY >= innerHeight/3) {
        // remove hidden class to show chat input
        if (currentInputRef.classList.contains(hiddenClassName)) {
          currentInputRef.classList.remove(hiddenClassName)
        }
      } else {
        // add hidden class to hide chat input
        if (!currentInputRef.classList.contains(hiddenClassName)) {
          currentInputRef.classList.add(hiddenClassName)
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    initChatBlackWord();
    getListMsg();
    chatPermission();

    // document.addEventListener('click', (event) => {
    //   setCanPlay(true);
    // })

    initVaiButton();
  }, []);

  useEffect(() => {

    if (completedLoadMsg === true && socket === undefined) {
      scrollChatMsgToBottom();

      initSocketIO();
    }

    // eslint-disable-next-line
  }, [completedLoadMsg, loading]);


  useEffect(() => {
    if (newMsg) {
      let deviceId = localStorage.getItem(Constant.LocalStorage.DeviceId)
      setListMsg([...listMsg, newMsg]);
      if (deviceId === newMsg.requestId) {
        setHighlightMsg(true);
        lockChat();
      }
    }
  }, [newMsg])

  useEffect(() => {
    scrollChatMsgToBottom();

    const timer = setTimeout(() => {
      setHighlightMsg(false);
    }, 3000);
    return () => clearTimeout(timer);

  }, [highlightMsg]);


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowVaiIcon(false)
    }, 2000);
    return () => clearTimeout(timer);
  }, [showVaiIcon]);

  useEffect(() => {
    if (canPlay === true && initSoundCompleted === false) {
      setInitSoundCompleted(true);
      initSound();
    }

    // eslint-disable-next-line
  }, [canPlay]);

  useEffect(() => {
    playAudio();
    setVolumnIcon(playingAudio ? volumn : volumeSlash);

    // eslint-disable-next-line
  }, [playingAudio]);

  const initSocketIO = function () {
    socket = io(API_BASE_URL, { transport: ['websocket'] });

    socket.on("connect", () => {
      let identify = localStorage.getItem(Constant.LocalStorage.DeviceId);
      if (!identify) {
        localStorage.setItem(Constant.LocalStorage.DeviceId, socket.id)
      }
    })

    socket.on("NEW_MESSAGE", function (msg) {
      setTimeout(
          () => {
            setNewMsg(msg);
          },
          400
      )
    });
  }

  const initChatBlackWord = function () {
    let listBw = JSON.parse(process.env.REACT_APP_BLACK_WORDS || "")
    listBw = listBw.map(item => {
      return item.toLowerCase().trim();
    })
    let _regexString = listBw.join("|");
    let _regex = new RegExp(_regexString, "g")

    setRegex(_regex);
  }

  const initVaiButton = function () {
    let lastAction = localStorage.getItem(Constant.LocalStorage.LastAction);
    if (lastAction) {
      lastAction = JSON.parse(lastAction);
    }
    else {
      lastAction = {
        count: 0,
        time: new Date()
      }
    }

    let hour = 60; // minute
    let minute = 60; // second

    let now = new Date();
    let diff = moment(now).diff(lastAction.time, "second");
    if (lastAction.count >= 3) {

      if (diff < 4 * hour * minute) {
        setAllowVai(false);
        setvaiBtnClss("btn-vai-hide");
        return;
      }
    }
  }

  const playAudio = function () {
    playingAudio ? audio.play() : audio.pause();
  }

  const handleOnchange = (event) => {
    let content = event.target.value;

    setMsgContent(content);
  }

  const getListMsg = function (from = 0) {
    if (loading === true) return;

    let lastID = ""
    if (listMsg.length > 0) {
      lastID = listMsg[0]._id
    }
    axios.get(API_BASE_URL + '/khan?itemPerPage=30&lastID=' + lastID)
      .then(function (response) {
        let list = response.data || [];
        setListMsg([...list.reverse(), ...listMsg]);

        if (list.length < 10) {
          setChatPageNumber(-1);
        }
        else {
          setChatPageNumber(chatPageNumber + 1)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        setCompletedLoadMsg(true);
        setLoading(false);
      });
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();

      clickToSubmit();
    }
  }

  const clickToSubmit = function () {
    let isValid = validMsg(msgContent);
    if (isValid === false) {
      setShowWarningChat(true);
      return;
    }
    setShowWarningChat(false);
    submitMsg(msgContent);
    setMsgContent("");
  }

  const validMsg = function (msg) {
    let _msgContent = msg.toLocaleLowerCase();

    let match = _msgContent.match(regex)

    return match === null;
  }

  const submitMsg = function (content) {
    let deviceId = localStorage.getItem(Constant.LocalStorage.DeviceId)
    axios.post(API_BASE_URL + '/khan', {
      requestId: deviceId,
      content: content
    })
      .then(function (response) {
        if (response.data.status === "success") {
          lockChat();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const lockChat = function () {
    let lastAction = {
      time: new Date()
    }

    localStorage.setItem(Constant.LocalStorage.LastKhan, JSON.stringify(lastAction));

    chatPermission();
  }

  const chatPermission = function () {
    let lastAction = localStorage.getItem(Constant.LocalStorage.LastKhan);
    if (!lastAction) return;

    lastAction = JSON.parse(lastAction);

    let hour = 60; // minute
    let minute = 60; // second

    let now = new Date();
    let diff = moment(now).diff(lastAction.time, "second");

    let disableChat = diff < 4 * hour * minute;
    setAllowChat(disableChat === false);
  }

  const clickToVai = function (event) {
    event.preventDefault();

    setvaiBtnClss("btn-vai-click");

    let lastAction = localStorage.getItem(Constant.LocalStorage.LastAction);
    if (lastAction) {
      lastAction = JSON.parse(lastAction);
    }
    else {
      lastAction = {
        count: 0,
        time: new Date()
      }
    }

    let hour = 60; // minute
    let minute = 60; // second

    let now = new Date();
    let diff = moment(now).diff(lastAction.time, "second");
    if (lastAction.count >= 3) {

      if (diff < 4 * hour * minute) {
        setAllowVai(false);
        setvaiBtnClss("btn-vai-hide");
        return;
      }
    }
    setShowVaiIcon(true);

    if (diff <= 1 * minute) {
      lastAction.count += 1;
    }
    else {
      lastAction.count = 1;
    }

    if (lastAction.count >= 3) {
      setAllowVai(false);
      setvaiBtnClss("btn-vai-hide");
    }
    else {
      setTimeout(() => {
        setvaiBtnClss("btn-vai-normal");
      }, 2000);
    }

    lastAction.time = now;

    localStorage.setItem(Constant.LocalStorage.LastAction, JSON.stringify(lastAction));
  }

  const initSound = function () {
    audio.loop = true;
    let sMode = localStorage.getItem(Constant.LocalStorage.PlayAudio);
    if (!sMode) {
      sMode = process.env.REACT_APP_DEFAULT_MUSIC;
    }

    if (sMode === Constant.AudioMode.On) {
      setPlayingAudio(true);
    }
  }

  const onOffSound = function (event) {
    event.preventDefault();
    let sOn = !playingAudio
    setPlayingAudio(sOn);

    let sMode = sOn ? Constant.AudioMode.On : Constant.AudioMode.Off;
    localStorage.setItem(Constant.LocalStorage.PlayAudio, sMode);
  }

  const ios = () => {
    if (typeof window === `undefined` || typeof navigator === `undefined`) return false;

    return /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor || window.opera);
  };

  const addBookMark = function (e) {
    e.preventDefault()
    let message = "Ấn " + (navigator.userAgent.toLowerCase().indexOf('mac') !== - 1 ? 'Command/Cmd' : 'CTRL') + ' + D để lưu trang'
    if (width < 1200) {
      if (ios()) {
        message = "Hãy vào ongdia.net bằng Safari, bấm nút Share và chọn \"Add to Home Screen\" để lưu trang này lên màn hình Home của iPhone";
      } else {
        message = "Hãy dùng chức năng Bookmark của trình duyệt để lưu trang này."
      }
    }
    alert(message)
  }

  const scrollChatMsgToBottom = function () {
    chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight);

    setChatBoxScrollHeight(chatBoxRef.current.scrollHeight);
  }

  const onScrollChat = function (e) {
    if (chatPageNumber === -1) return;

    if (chatBoxRef.current.scrollTop <= 50) {
      e.preventDefault();
      setLoading(true);
      getListMsg()
    }

  }

  const reversedItems = React.useMemo(() => {
    return [...listMsg];
  }, [listMsg]);

  const rootRefSetter = React.useCallback(
    (node) => {
      chatBoxRef.current = node;
    },
    [],
  );

  React.useEffect(() => {
    setChatBoxScrollHeight(chatBoxRef.current.scrollHeight);
    const scrollableRoot = chatBoxRef.current;
    const lastScrollDistanceToBottom =
      chatBoxScrollHeight ?? 0;
    if (scrollableRoot) {
      if (loading === true) {
        scrollableRoot.scrollTop = scrollableRoot.scrollHeight - lastScrollDistanceToBottom;
      }
      else if (scrollableRoot.scrollTop + scrollableRoot.clientHeight > scrollableRoot.scrollHeight - 150) {
        scrollableRoot.scrollTop = scrollableRoot.scrollHeight;
      }
    }

  }, [reversedItems, loading]);

  return (
    <div className="row col-sm-12 col-md-12 col-lg-12 col-xl-12 container">
      <div className="row col-lg-9 col-xl-9 left left-box not-effect">
        <div className="row col-sm-12 col-md-12 col-lg-12 col-xl-12 top-box not-effect">
          <span id="repro">
            <canvas id="canvasMiddle"></canvas>
            <canvas id="canvasLeft"></canvas>
            <canvas id="canvasRight"></canvas>
          </span>
          <img alt="" className="ong-dia-img" src={ongdiaSmall}></img>

          <div className="top-btn-box">
            <button className="btn bookmark-btn" onClick={addBookMark}>
              <img alt="" src={bookmarkIcon}></img>
              <span className="bookmark-text">Lưu trang</span>
            </button>
            <button className="btn sound-btn" onClick={onOffSound}><img alt="" src={volumnIcon}></img></button>
          </div>

        </div>
        <div className="row col-sm-12 col-md-12 col-lg-12 col-xl-12 bottom-box">
          <div style={{ "width": "100%" }}>
            <div style={{ "height": "0" }}>
              <FadeInAnimation direction="up" className="vai-icon" wrapperElement="button" show={showVaiIcon}>
              </FadeInAnimation>
            </div>

            <div className="btn-vai-box">
              <button className={vaiBtnClss} onClick={clickToVai}></button>
            </div>

            {allowVai === true ?
              (<div className="text-center mt-2">
                <span className="text">{process.env.REACT_APP_TEXT_1}</span>
                <br />
                <span className="text">{process.env.REACT_APP_TEXT_2}</span>
              </div>)
              :
              renderLockVaiCountdown()
            }

            {renderAds()}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-xl-3 right-box">
        <div className="right">
          {renderTabletAds()}
          <div className="chat-container">
            <div className="header">
              <span>Lời khấn tứ phương</span>
            </div>
            <div className="chat-box-container">
            <div
                className={`chat-content ${allowChat ? "chat-content-when-fixed-comments" : ""}`}
                ref={rootRefSetter}
                onScroll={onScrollChat}
            >
              <section>
                {renderMessages()}
              </section>
            </div>
            </div>

            {/* </ScrollToBottom> */}
            {renderChatInputBox()}
            {renderShareButton()}
            {renderImageSource()}
          </div>
        </div>
      </div>
    </div>
  );

  function renderMessages() {
    let arrLen = reversedItems.length;
    return reversedItems.map((msg, index) => {
      let highLightClass = highlightMsg && index === arrLen - 1 ? "msg-highlight" : "";
      return (<Message
          content={msg.content}
          highLightClass={highLightClass}
          id={msg._id}
          time={msg.time}
          key={msg._id}
      />)
    })
  }

  function renderChatInputBox() {
    if (allowChat === true) {
      return (
          <div className="chat-input-box-mobile hidden" ref={chatInputBoxRef}>
            <div className="chat-input-box">
              <TextareaAutosize
                  maxRows={4}
                  minRows={1}
                  className="chat-input"
                  placeholder="Viết lời khấn của bạn tại đây."
                  value={msgContent}
                  onChange={handleOnchange}
                  onKeyDown={handleKeyPress}
              />
              <img alt="" className={msgContent.length > 0 ? "send-icon" : "send-icon opacity-50"} src={vectorIcon}
                   onClick={clickToSubmit}></img>
            </div>
            {renderInputLabel()}
          </div>
      );
    }

    return renderLockChatCountDown()
  }

  function renderInputLabel() {
    if (showWarningChat === true) {
      return (
          <div className="chat-note">
            <span className="text-note-warning">Xin khấn văn minh, lịch sự</span>
          </div>
      )
    }

    if (allowChat === true) {
      return (
          <div className="chat-note">
            <span className="text-note">Chỉ một mình bạn biết nội dung khấn</span>
          </div>
      )
    }
  }

  function hiddenShareButton() {
    if (window === undefined) return true;
    return showWarningChat === true || allowChat === true
  }

  function renderImageSource() {
    if (!hiddenShareButton()) {
      return
    }
    return (
        <div className={allowChat ? "image-license-box license-box-opacity" : "image-license-box"}>
          <span className="image-license">Nguồn hình: Buulien.vn</span>
        </div>
    )
  }

  function renderShareButton() {
    if (hiddenShareButton()) {
      return
    }

    const isMobile = window.innerWidth <= 500
    return (
      <div className="chat-note">
        <FacebookShareButton
          url={process.env.REACT_APP_DOMAIN}
          quote={"ông địa"}
          className=""
          style={{ "paddingTop": "18px" }}
          onClick={(e) => {
            e.preventDefault();
            if (isMobile) {
              if (navigator.share) {
                navigator.share({
                  title: 'ông địa',
                  url: `${process.env.REACT_APP_DOMAIN}`,
                }).then(() => {
                  console.log('Thanks for sharing!');
                })
                    .catch(console.error);
              } else {
                console.log("fallback");
              }
            }
          }}
          openShareDialogOnClick={isMobile === false}
        >
          <img alt="" src={fbIcon} style={{ "paddingRight": "15px" }}></img>
          <span className="share-fb-text">Chia sẻ ongdia.net</span>
        </FacebookShareButton>
      </div>
    )

  }

  function renderLockChatCountDown() {

    let lastAction = localStorage.getItem(Constant.LocalStorage.LastKhan);
    if (!lastAction) return;

    lastAction = JSON.parse(lastAction);

    let next = moment(lastAction.time).add(4, "hours").toDate();

    return <Countdown
      date={next}
      renderer={renderLockChatCountDownRenderer}
      onComplete={() => setAllowChat(true)}
    />
  }

  function renderLockChatCountDownRenderer({ hours, minutes, seconds, completed }) {
    let nextTimeAction = hours + " giờ " + minutes + " phút " + seconds + " giây"
    return (
      <div className="chat-input-box">
        <article className={"msg-container msg-highlight"}>
          <div className="msg-box">
            <div className="flr">
              <div className="messages">
                <p className="msg">
                  Ông Địa và Thần Tài đã nhận được lời khấn của bạn, an tâm nhé! Hãy khấn vừa đủ để đảm bảo ứng nghiệm. Bạn có thể khấn tiếp sau <span className="time-countdown">{nextTimeAction}</span>
                </p>
              </div>
            </div>
          </div>
        </article>
      </div>
    )
  };

  function renderLockVaiCountdown() {
    let lastAction = localStorage.getItem(Constant.LocalStorage.LastAction);
    if (!lastAction) return;

    lastAction = JSON.parse(lastAction);

    let next = moment(lastAction.time).add(3, "hours").add(59, "minutes").add(59, "seconds").toDate();

    return <Countdown
      date={next}
      renderer={renderLockVaiCountdownRenderer}
      onComplete={() => {
        setAllowVai(true);
        setvaiBtnClss("btn-vai-normal");
      }}
    />
  }

  function renderLockVaiCountdownRenderer({ hours, minutes, seconds, completed }) {
    let nextTimeAction = hours + " giờ " + minutes + " phút";
    return <div className="text-center mt-2">
      <span className="text">Hãy vái vừa đủ để đảm bảo ứng nghiệm. Bạn có thể vái tiếp sau <span className="time-countdown">{nextTimeAction}</span></span>
    </div>
  }

  function renderAds() {
    if (enableAds === false) {
      return null;
    }

    return <div className="desktop-ads">
      <GoogleAdSense>
        html={
          '<ins class="adsbygoogle"\n' +
          'style="display:inline-block;width:100%;height:100px"\n' +
          'data-ad-client="ca-pub-6515649480893405"\n' +
          'data-ad-slot="8803880029"' +
          'data-ad-format="auto"' +
          'data-full-width-responsive="true"></ins>'
        }
      </GoogleAdSense>

    </div>
  }

  function renderTabletAds() {
    if (enableAds === false || isTablet === false) {
      return null;
    }

    return <div className="tablet-ads">
      <GoogleAdSense>
        html={
          '<ins class="adsbygoogle"\n' +
          'style="display:block;width:100%;height:100px"\n' +
          'data-ad-client="ca-pub-6515649480893405"\n' +
          'data-ad-slot="8803880029"' +
          'data-ad-format="auto"' +
          'data-full-width-responsive="true"></ins>'
        }
      </GoogleAdSense>

    </div>
  }
};

export default App;
